<template>
    <main class="flex-grow-1 align-content-center">
        <div class="container h-100">
            <div>
                <div style="width: 0;" class="d-none d-lg-block mt-5">
                    <h1 class="">Terms&nbsp;&amp; Conditions</h1>
                </div>
                <h1 class="d-block d-lg-none mt-4">Terms &amp; Conditions</h1>
            </div>
            
            <div class="mt-5">
                <h6>Definitions</h6>
                <ul>
                    <li>We/Us - EtsyOrderPrinter</li>
                    <li>You - yourself</li>
                </ul>

                <div class="mt-2">We are still hard at work making our app, and if you have been linked here then you are most likely a part of the private beta.</div>

                <div class="mt-2">That being said, we do not have a fully laid out Terms of Service as of now, but suffice it to say, we treat your data as if it was our own.</div>
                <div class="mt-2">We do not work with any third parties and you may cancel at any time for any reason, and we can cancel your service at any time for any reason.</div>
                <div class="mt-2">Upon cancellation, you may receive a refund, pro-rated if you email us.</div>

                <div class="mt-5">This terms and conditions will be updated once the app is fully ready for public consumption.</div>
                
                <div class="mt-5">This terms and conditions was last updated 18 Aug 2021.</div>
            </div>
        </div>
    </main>
</template>

<script>
export default {

}
</script>

<style>

</style>